/**
 * Mobile Menu
 * @dependencies jQuery
 * @dependencies Foundation
 */

import $ from 'jquery';
import { Drilldown } from 'fjs/foundation.drilldown';

Foundation.plugin(Drilldown, 'Drilldown');

const $triggerMenu = $('.js-menu-toggle');
const sizeChangeEvents = ['resize', 'orientationchange'];
let currentWidth = window.innerWidth;
let $drilldownEl;

if($triggerMenu.length) {
  // We dont need fast load, so we can keep the slow `load` event lifecycle
  window.addEventListener('load', function(event) {
    // ### DrillDown Menu
    // Settings
    $drilldownEl = $('.js-mobile-menu');
    const drilldownBackText = $drilldownEl.attr('data-back');
    const drilldownOptions = {
      wrapper: `<nav class="responsive-menu__nav"></nav>`,
      backButton: `<li class="js-drilldown-back"><a class="drilldown-back" tabindex="0">${drilldownBackText}</a></li>`,
      // closeOnClick: true,
      parentLink: true,
      //scrollTop: true,
      autoHeight: true,
      animateHeight: true
    };

    // Init
    const drilldownInstance = new Drilldown($drilldownEl, drilldownOptions);

    // ### Toggle Open/Close
    $triggerMenu.on('click', function(ev){
      ev.preventDefault();
      $('body').toggleClass('nav-open');
    });
  });

  // To trigger drilldown refresh on resize / orientation change
  sizeChangeEvents.forEach((event) => {
    window.addEventListener(event, debounce(refreshDrilldown));
  });
}

// Debounce the event to avoid too many triggers
function debounce(func, timeout = 250){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}

// Destroy the last instance to rebuild with correct sizings
function refreshDrilldown() {
  const newWidth = window.innerWidth;
  if(currentWidth > 1024 && newWidth < 1024) {
    currentWidth = window.innerWidth;
    $($drilldownEl).removeClass('invisible');
    $($drilldownEl).foundation('_destroy');
    Foundation.reInit('drilldown');
  } else {
    currentWidth = window.innerWidth;
  }
}
