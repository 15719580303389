import $ from 'jquery';

window.addEventListener('load', function() {

  if ( ! document.querySelector('.page-template-template-rapport') )
    return;

  const urlObject = new URL(location.href);
  urlObject.search = '';
  // window.history.pushState("", "", urlObject.toString());

});
