/**
 * Facet WP plugin scripts
 */

import $ from 'jquery';
import { lazyLoadObserve } from './lazyLoad';
import Jump from 'jump.js'
import { easeOutQuad } from 'ez.js';

window.addEventListener('load', () => {
  if (typeof FWP === 'object') {

    // For the first init it doesn't always triggers the `facetwp-loaded` event
    // So use this aswell
    if (FWP.loaded) {
      console.log('FWP Loaded');
    }

    // This is needed to make the scrolltop after facet has loaded the new content after paging
    let hasRefreshed = false;
    const fwpPager = Array.from(document.getElementsByClassName('facetwp-page'));
    fwpPager.forEach((pager) => {
      pager.addEventListener('click', function () {
        hasRefreshed = true;
      })
    })

    const loaders = Array.from(document.getElementsByClassName('js-loader'));
    const itemsList = document.querySelector('.facetwp-template');

    manageListFeaturedItemClass(itemsList);

    $(document).on('facetwp-loaded', function () {
      const defaultObserver = lazyLoadObserve();
      defaultObserver.observe();

      if (hasRefreshed) {
        Jump('.main', {
          duration: 800,
          easing: easeOutQuad,
        });
      }

      if (loaders.length) {
        loaders.forEach((loader) => {
          loader.classList.remove('is-loading');
        })
      }

      itemsList.classList.remove('is-loading');

      manageListFeaturedItemClass(itemsList);

    });

    $(document).on('facetwp-refresh', function() {
      if (loaders.length) {
        loaders.forEach((loader) => {
          loader.classList.add('is-loading');
        })
      }

      itemsList.classList.add('is-loading');
    });
  }
});

function manageListFeaturedItemClass(list) {

  const urlParams = new URLSearchParams(window.location.search);

  if ( FWP.facets.category.length || urlParams.get('_paged') > 1 )
    list.querySelector('li:first-child').classList.remove('posts__item--featured');
  else
    list.querySelector('li:first-child').classList.add('posts__item--featured');

}
