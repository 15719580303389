import $ from 'jquery';
import { gsap } from "gsap";
import * as estimLoader from '../components/estimLoader';

// Display map into GravityForms field
$(document).on('gform_post_render', function(event, form_id, page){

  if ( ! document.querySelector('#gf_1') || ! document.querySelector('.js-mapbox-datas') || page != 1 )
    return;

  // Apply address availability value to related field
  const isAddressAvailable = document.querySelector('#main-section').dataset.isaddressavailable;

  if (isAddressAvailable == "1") {
    document.querySelector('#input_1_27').value = isAddressAvailable;
  } else {
    document.querySelector('#choice_1_49_0').checked = true;
    document.querySelector('#choice_1_49_0').dispatchEvent(new Event('change'));

    // Hide previous button
    const styleSheet = document.createElement("style");
    styleSheet.innerText = '#gform_page_1_2 .button.gform_previous_button { display : none }';
    document.head.appendChild(styleSheet);

    removeLoader();
  }

  const mapboxField = document.querySelector('#input_1_25');
  const mapboxSection = document.querySelector('.js-mapbox');
  const urlObject = new URL(location.href);
  const searchedCoordinates = urlObject.searchParams.get("address") ? urlObject.searchParams.get("address").split('_') : '';
  const searchedLng     = searchedCoordinates ? searchedCoordinates[0] : '';
  const searchedLat     = searchedCoordinates ? searchedCoordinates[1] : '';
  const searchedAddress = searchedCoordinates ? searchedCoordinates[2].replace(/-/g, ' ') : '';
  if ( ! searchedLng || ! searchedLat || ! searchedAddress )
    return;

  document.querySelector('#input_1_40').value = searchedAddress;
  document.querySelector('#input_1_41').value = searchedLat;
  document.querySelector('#input_1_42').value = searchedLng;

  let mapboxSectionData;
  let searchedCityPolygons;
  let searchedCitycode;
  let selectedPolygons = [];
  let polygons = [];
  let polygonPoints = [];
  let activatedPolygons = [];
  let mapboxSectionClone;

  // Set Mapbox token
  mapboxgl.accessToken = 'pk.eyJ1Ijoic3BpcmlpdCIsImEiOiJjbGVmcG1vaWswYXZxNDFxZGVjZjR2ajZ3In0.RL7Rq13ZZgVDI-j1rsUZKg';

  // Reset url
  // urlObject.search = '';
  // window.history.pushState("", "", urlObject.toString());

  // Display the mapbox section after the form hidden field
  mapboxSectionClone = mapboxSection.cloneNode(true);
  mapboxSectionClone.classList.remove('hide');
  mapboxField.closest('.gfield.gform_hidden').after(mapboxSectionClone);
  mapboxSectionData = mapboxSectionClone.querySelector('.js-mapbox-datas');

  if(page == 1) {
    document.getElementById('gf_progressbar_wrapper_1').classList.add('hide');
  } else {
    document.getElementById('gf_progressbar_wrapper_1').classList.remove('hide');
  }

  const map = new mapboxgl.Map({
    container: 'mapbox-map',
    style: 'mapbox://styles/spiriit/clbg9o3l5000214pfqzgearsh',
    center: searchedCoordinates,
    zoom: 18
  });

  map.on('load', () => {
    map.resize();

    removeLoader();
  });

  // Mapbox events
  fetch('https://api-adresse.data.gouv.fr/reverse/?lon=' + searchedLng + '&lat=' + searchedLat)
    .then((response) => response.json())
    .then((data) => {
      if ( data.features[0].properties.citycode ) {

        searchedCitycode = data.features[0].properties.citycode;

        fetch('https://cadastre.data.gouv.fr/bundler/cadastre-etalab/communes/' + searchedCitycode + '/geojson/parcelles')
          .then((response) => response.json())
          .then((data) => {

            // Store searched city Polygons
            searchedCityPolygons = data.features;
            searchedCityPolygons.forEach((polygon) => {

              polygonPoints = polygon.geometry.coordinates[0];
              polygons.push(polygonPoints);

              // Store active polygons. Searched address polygon by default, selected ones if User has stepped back
              if ( mapboxSection.dataset.value === '' ) {
                if ( turf.booleanPointInPolygon(turf.point(searchedCoordinates), turf.polygon([polygonPoints])) ) {
                  selectedPolygons.push(polygon);
                }
              } else {
                JSON.parse(mapboxSection.dataset.value).forEach((mapboxSectionPolygon) => {
                  if( mapboxSectionPolygon.id === polygon.id ) {
                    selectedPolygons.push(polygon);
                  }
                });
              }

            });

            // Add searched city polygons to map
            if ( polygons ) {
              addPolygonsToMap(map, searchedCitycode, polygons, false);
            }

            // Add searched address polygon to map
            selectedPolygons.forEach((selectedPolygon) => {
              switchPolygonState(map, 'polygon-' + selectedPolygon.id, selectedPolygon, mapboxSectionData, activatedPolygons, mapboxField, mapboxSection);
            });

            // On click on a polygon
            map.on('click', 'layer-' + searchedCitycode, (e) => {

              // Switch polygon state (enabled/disabled)
              searchedCityPolygons.forEach((polygon) => {
                if ( turf.booleanPointInPolygon(turf.point([e.lngLat.lng, e.lngLat.lat]), turf.polygon([polygon.geometry.coordinates[0]])) ) {
                  switchPolygonState(map, 'polygon-' + polygon.id, polygon, mapboxSectionData, activatedPolygons, mapboxField, mapboxSection);
                }
              });

            });

            // Reset all polygons
            document.querySelector('.js-delete-polygons').addEventListener('click', function(){

              // Remove layers
              activatedPolygons.forEach((polygon) => {
                map.removeLayer('outline-' + 'polygon-' + polygon.id);
                map.removeLayer('layer-' + 'polygon-' + polygon.id);
                map.removeSource('source-' + 'polygon-' + polygon.id);
              });
              activatedPolygons = [];

              // Remove table data
              mapboxSectionData.innerHTML = "";

              // Hide "Delete polygons" button & total area
              document.querySelector('.js-delete-polygons').classList.add('hide');
              document.querySelector('.js-total-area').classList.add('hide');

              // Store selected polygons data into hidden field value
              mapboxSection.dataset.value = '';
              mapboxField.value = '';

            });

          });
      }

    });

});

/**
 * Update layers on Map + polygons data + hidden field data
 */
function switchPolygonState(map, layerName, polygon, mapboxSectionData, activatedPolygons, mapboxField, mapboxSection) {

  // Remove potential previous data
  if ( map.getLayer('outline-' + layerName) && map.getLayer('layer-' + layerName) && map.getSource('source-' + layerName) ) {

    // Remove current polygon from activatedPolygons
    activatedPolygons.splice(activatedPolygons.indexOf(polygon), 1);

    // Remove Polygon from map
    map.removeLayer('outline-' + layerName);
    map.removeLayer('layer-' + layerName);
    map.removeSource('source-' + layerName);

  } else {

    // Add current polygon to activatedPolygons
    activatedPolygons.push(polygon);

    // Add Polygon to map
    addPolygonsToMap(map, layerName, polygon.geometry.coordinates, true);

    document.querySelector('.js-delete-polygons').classList.remove('hide');

  }

  // Potentially hide Parcels data
  const totalAreaEl = document.querySelector('.js-total-area');
  mapboxSectionData.parentElement.classList.add('hide');
  totalAreaEl.classList.add('hide');
  let totalArea = 0;

  // Update polygons data
  mapboxSectionData.innerHTML = "";
  let currentPolygons = [];
  if ( activatedPolygons.length ) {
    activatedPolygons.forEach((polygon) => {
      let polygonArea = Math.round(turf.area(polygon));

      // Update polygons data into list
      const li = document.createElement("li");

      const parcelInfos = {
        section: { label: 'Section', value: polygon.properties.section },
        number: { label: 'Numéro', value: polygon.properties.numero },
        area: { label: 'Superficie', value: polygonArea + 'm²' }
      }

      Object.values(parcelInfos).forEach((val) => {
        li.innerHTML += `<div class="parcel__info">
          <span class="parcel__label">${val.label}</span>
          <span class="parcel__value">${val.value}</span>
        </div>
        `;
      });
      // li.innerHTML += `<div class="parcel__info parcel__info--delete"></div>`;

      li.classList.add('parcels__item', 'parcel');
      mapboxSectionData.appendChild(li);

      // Store selected polygons data
      currentPolygons.push({
        'id' : polygon.id,
        'section' : polygon.properties.section,
        'number' : polygon.properties.numero,
        'area' : polygonArea,
      });

      totalArea += polygonArea;

    });

    if ( totalArea ) {
      mapboxSectionData.parentElement.classList.remove('hide');
      totalAreaEl.classList.remove('hide');
      totalAreaEl.querySelector('.mapbox__totalarea__value').innerHTML = totalArea;
    }

    // Store selected polygons data into hidden field value
    mapboxSection.dataset.value = JSON.stringify(currentPolygons);
    mapboxField.value = JSON.stringify(currentPolygons);
  }

}

/**
 * Add polygons to map
 */
function addPolygonsToMap(map, layerName, coordinates, areHighlitedPolygons = true) {

  // Add a data source containing GeoJSON data.
  map.addSource('source-' + layerName, {
    'type': 'geojson',
    'data': {
      'type': 'Feature',
      'geometry': {
        'type': 'Polygon',
        'coordinates': coordinates
      }
    }
  });

  // Add a new layer to highlight the polygon
  map.addLayer({
    'id': 'layer-' + layerName,
    'type': 'fill',
    'source': 'source-' + layerName, // reference the data source
    'layout': {},
    'paint': {
      'fill-color': '#2DD39C',
      'fill-opacity': areHighlitedPolygons ? 1 : 0,
      'fill-outline-color': '#001D6C'
    }
  });

  // Add an outline around the polygon.
  map.addLayer({
    'id': 'outline-' + layerName,
    'type': 'line',
    'source': 'source-' + layerName, // reference the data source
    'layout': {},
    'paint': {
      'line-color': areHighlitedPolygons ? '#001D6C' : '#6DCAAC',
      'line-width': 2,
    }
  });
}

function removeLoader() {
  estimLoader.loaderTL.add('loadingDone')
    .to(estimLoader.loaderLogo, 0.75, { y: -40, opacity: 0, ease: "power2.out" }, 'loadingDone+=0.25')
    .to(estimLoader.loaderBaseline, 0.75, { y: -20, opacity: 0, ease: "power2.out" }, 'loadingDone+=0.5')
    .to(estimLoader.loaderProgress, 1.25, { transformOrigin: 'right center', scaleX: 0, opacity: 0, ease: "expo.out", onComplete: function () {
      document.querySelector('html').classList.remove('is-loading');
      document.body.classList.remove('is-loading');
    }}, 'loadingDone-=0.1')
    .to(estimLoader.loader, 0.65, { autoAlpha: 0, ease: "power1.out" }, 'loadingDone+=1.5');
}
