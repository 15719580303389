// YouTube Iframe API
const tag = document.createElement('script');
tag.src = 'https://www.youtube.com/iframe_api';
tag.classList.add('js-iframe-api');
const firstScriptTag = document.getElementsByTagName('script')[0];

// If the YouTube Iframe API <script> doesn't exist
const iframeAPI = document.querySelector('.js-iframe-api');
if (!iframeAPI) {
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

// Inits custom YouTube player
const initPlayer = function (player) {
  if (typeof YT == 'undefined') return;

  const playerIframe = player.querySelector('.youtube-player__target');
  const playerOverlay = player.querySelector('.youtube-player__overlay');

  playerOverlay.classList.add('hide');

  // Set the player options
  const playerOptions = {
    autoplay: 0,
    mute: 0,
    autohide: 1,
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
    controls: 2,
    disablekb: 1,
    enablejsapi: 1,
    iv_load_policy: 3,
  };

  new YT.Player(playerIframe, {
    width: '1280',
    height: '720',
    playerVars: playerOptions,
    videoId: player.dataset.videoid,
  });
};

// Destroys playeruU5vVT_Cp7c
const destroyPlayer = function (player) {
  const playerId = player.querySelector('.youtube-player__target').id;
  const playerOverlay = player.querySelector('.youtube-player__overlay');

  playerOverlay.classList.remove('hide');

  if (playerId) {
    YT.get(playerId).destroy();
  }
};

// When YouTube API is ready, inits player depending on cookies consent
window.onYouTubePlayerAPIReady = function () {
  const players = Array.from(document.getElementsByClassName('js-youtube-player'));

  if (players.length) {
    for (let i = 0; i < players.length; i++) {
      const player = players[i];

      // Axeptio SDK
      window._axcb = window._axcb || [];
      window._axcb.push(function (sdk) {
        sdk.on('cookies:complete', function (choices) {
          if (choices.youtube) {
            initPlayer(player);
          } else {
            destroyPlayer(player);
          }
        });

        // Axeptio custom consent request
        document.querySelectorAll('.js-youtube-cookie').forEach((buttonConsent) => {
          buttonConsent.addEventListener('click', () =>
            sdk.requestConsent('youtube'),
          );
        });
      });
    }
  }
};
