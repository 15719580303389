/**
 * Smooth Scroll when click on link
 * @dependencies jump.js
 * @dependencies ez.js
 */
/**
 * Smooth Scroll when click on link
 */
import Jump from 'jump.js'
import { easeInOutCubic } from 'ez.js';

const links = document.getElementsByClassName('js-smooth-scroll');
for (let index = 0; index < links.length; index++) {
  const el = links[index];
  const url = el.getAttribute('href');
  const hash = url.substring(url.indexOf('#') + 1);
  const duration = el.getAttribute('data-jump-duration') ? el.getAttribute('data-jump-duration') : 800;

  el.addEventListener('click', (ev) => {
    ev.preventDefault();
    const headerHeight = document.querySelector('.js-fixed-header').offsetHeight;

    Jump(`#${hash}`, {
      duration: duration,
      offset: -headerHeight,
      easing: easeInOutCubic
    })
  })
}
