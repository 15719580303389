import { gsap } from "gsap";

export const loaderTL = gsap.timeline();
export const loader = document.querySelector('.js-estim-loader');
export let loaderLogo, loaderBaseline, loaderProgress, loaderProgressBar;

if (loader) {
  loaderLogo = loader.querySelector('.estim-loader__logo');
  loaderBaseline = loader.querySelector('.estim-loader__txt');
  loaderProgress = loader.querySelector('.estim-loader__progress');
  loaderProgressBar = loader.querySelector('.estim-loader__bar');

  loaderTL.add('start')
    .to(loaderLogo, 0.75, { y: -20, opacity: 1, ease: "power2.out" }, 'bgArrived-=0.25')
    .to(loaderBaseline, 0.75, { y: 0, opacity: 1, ease: "power2.out" }, 'bgArrived')
    .to(loaderProgress, 1.25, { scaleX: 1, ease: "expo.inOut" }, 'bgArrived+=0.25')
    .to(loaderProgressBar, 2, { scaleX: 0.45, ease: "power1.out" })
    .to(loaderProgressBar, 1.25, { scaleX: 1, ease: "expo.inOut" });
}

