/**
 * Fixed Header with headroom.js
 * @dependencies headroom.js
 */

import Headroom from 'headroom.js';

const sizeChangeEvents = ['resize', 'orientationchange'];

// Header Element
const headerEl = document.querySelector('.js-fixed-header');
let headerHeight = headerEl.offsetHeight;

// function to calculate height depending on breakpoints
const calcHeaderHeight = () => {
  headerHeight = headerEl.offsetHeight;
  document.documentElement.style.setProperty('--header-height', `${headerHeight}px`);
}

// Headroom Options
const fixedHeader = new Headroom(headerEl, {
  classes : {
    // when element is initialised
    initial : "initialized",
    // when scrolling up
    pinned : "is-pinned",
    // when scrolling down
    unpinned : "is-unpinned",
    // when above offset
    top : "is-top",
    // when below offset
    notTop : "is-not-top",
    // when at bottom of scoll area
    bottom : "is-bottom",
    // when not at bottom of scroll area
    notBottom : "is-not-bottom"
  }
});

window.addEventListener('load', function() {
  fixedHeader.init();
  calcHeaderHeight();

  sizeChangeEvents.forEach((event) => {
    window.addEventListener(event, debounce(calcHeaderHeight));
  });
});

// Debounce the event to avoid too many triggers
function debounce(func, timeout = 250){
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => { func.apply(this, args); }, timeout);
  };
}


