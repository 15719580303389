/**
 * LazyLoading & Intersection Observer
 */

import lozad from 'lozad'
import Masonry from 'masonry-layout'

let masonryTimeout;

// Images
export function lazyLoadObserve() {
  const simpleLazyImgs = document.querySelectorAll('.lazy-image img');
  const observer = lozad(simpleLazyImgs, {
    threshold: 0.1,
    load: function(el) {
      el.src = el.getAttribute('data-src');
      el.srcset = el.getAttribute('data-srcset');
    },
    loaded: function(el) {
      el.removeAttribute('data-src');
      el.removeAttribute('data-srcset');
      el.onload = function() {
        this.parentNode.setAttribute('data-fully-loaded', true);
      };

      const gridMasonry = el.closest('.js-masonry');
      if (gridMasonry) {
        clearInterval(masonryTimeout);
        masonryTimeout = setTimeout(() => {
          const masonry = Masonry.data(gridMasonry);
          masonry.layout();
        }, 100)
      }
    }
  });
  return observer;
}

const defaultObserver = lazyLoadObserve();
defaultObserver.observe();

const revealImgsContainer = Array.from(document.getElementsByClassName('reveal-lazy-image'));
const revealImgs = document.querySelectorAll('.reveal-lazy-image img');

if(revealImgsContainer.length) {
  const revealImgObserver = lozad(revealImgs, {
    threshold: 0.2,
    load: function(el) {
      el.src = el.getAttribute('data-src');
      el.srcset = el.getAttribute('data-srcset');
    },
    loaded: function(el) {
      el.removeAttribute('data-src');
      el.removeAttribute(' data-srcset');
      el.onload = function() {
        this.parentNode.classList.add('reveal-me');
        this.setAttribute('data-fully-loaded', true);
      };
    }
  });
  revealImgObserver.observe();
}
