window.addEventListener('load', function() {

  const mapboxToken = 'pk.eyJ1Ijoic3BpcmlpdCIsImEiOiJjbGVmcG1vaWswYXZxNDFxZGVjZjR2ajZ3In0.RL7Rq13ZZgVDI-j1rsUZKg';
  const geocoder = document.querySelector('.js-geocoder');
  const submitButton = document.querySelector('.js-geocoder-submit');

  if ( ! mapboxToken || ! submitButton || ! geocoder )
    return;

  // Create an autocomplete
  const mapboxGeocoder = new MapboxGeocoder({
    accessToken: mapboxToken,
    countries: 'fr',
    mapboxgl: mapboxgl,
    placeholder: geocoder.dataset.placeholder
  });
  mapboxGeocoder.addTo(geocoder);

  // Store selected address coordinates into button link
  mapboxGeocoder.on('result', function(result) {
    if ( result && result.result.center ) {
      submitButton.setAttribute('href', submitButton.dataset.estimatepageurl + '?address=' + result.result.center[0] + '_' + result.result.center[1] + '_' + result.result.place_name.replace(/ /g, '-') );
      submitButton.removeAttribute('disabled');
    }
  })

});

