// Import all your components here
// But don't code inside this file.
// camelCase your file name

// Utilities first so you can use it through all your other files
// import './utils/polyfills'; // Only if IE 11 & Edge 15
import './utils/utils';

// Global files next, to use everywhere. Potentially needing utilities.
// Don't forget all the dependencies needed by your framework.
// Foundation needs jQuery & what-input to work.
import $ from 'jquery';
import 'what-input';

// Foundation 6.6.3
import './global/foundation';
$(document).foundation();

// Alternatively you can import the whole lot using
// import * as turf from '@turf/turf'

// Components
import './components/facetwp';
import './components/fixedHeader';
import './components/geocoder';
import './components/gravityForm';
// import './components/lazyLoad';
import './components/loadPosts';
import './components/estimLoader';
// import './components/masonry';
import './components/mapbox';
import './components/mobileMenu';
// import './components/photoswipe';
import './components/report';
// import './components/searchbar';
import './components/socialSharerToggle';
// import './components/swiper';
import './components/smoothScroll';
import './components/youtubeIframe';

// Enables HMR for JS files imported here
if (module.hot)
  module.hot.accept();

// Signed by Spiriit!
console.log(`%cMade with ❤️ by Spiriit!`, `color: #009ee0;`);
