/**
 * Social Sharer using AddToAny (Wordpress Plugin)
 */

const socialSharerToggle = document.querySelector('.js-social-sharer-toggle');

if (socialSharerToggle) {
  socialSharerToggle.addEventListener('click', function(){
    this.parentNode.classList.toggle('social-sharer--opened');
  });
}

