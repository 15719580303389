/**
 * Gravity Form
 * Adds Material Design Floating Labels on Gravity Forms
 * @dependencies jquery https://jquery.com/
 */

import $ from 'jquery';

/**
 * Init post render (and reinit after ajax error / success)
 * @url https://docs.gravityforms.com/gform_post_render/
 */
$(document).on('gform_post_render', function(event, form_id, current_page){

  // Remove Loading class if exists
  document.body.classList.remove('is-loading');

  // Add readOnly attribute to readonly classed fields
  const readOnlyFields = document.querySelectorAll('.readonly input');
  if (readOnlyFields.length) {
    for (const readOnlyField of readOnlyFields) {
      readOnlyField.readOnly = true;
    }
  }
});
